import React from 'react'
import { Helmet } from 'react-helmet'
import { Footer } from '../../components/Layout/Footer'
import { Container } from '../../components/UI/Container'
import { Rule15c33SecMarketContent } from '../../views/articles/Rule15c33SecMarket'
import { Navbar } from '../../components/Layout/Navbar'

const Rule15c33Page = () => {
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Rule 15c3-3 Qualified Custody of Securities and Its Importance in Secondary Market Trading — Commercium Bank, Inc</title>
      </Helmet>

      <Navbar />

      <Container tw="pt-32 pb-20">
        <Rule15c33SecMarketContent />
      </Container>

      <Footer />
    </div>
  )
}

export default Rule15c33Page
