import React from 'react'
import { Title } from '../../components/UI/Title'
import { Subtitle } from '../../components/UI/Subtitle'
import { Link } from 'gatsby'

export const Rule15c33SecMarketContent = () => {
  return (
    <div>

      <article tw="mt-10 space-y-6">
        <Title>
          Rule 15c3-3 Qualified Custody of Securities and Its Importance in Secondary Market Trading
        </Title>
        <p>
          Rule 15c3-3 qualified custody of securities is an essential component of the securities market, playing a crucial role in safeguarding customer assets and promoting investor confidence.
        </p>
        <p>
          The Securities Exchange Act Rule 15c3-3, commonly referred to as the Customer Protection Rule, is a critical regulation that aims to safeguard the funds and securities of customers held by broker-dealers. This rule imposes specific requirements on firms to ensure the protection of customer assets and the proper segregation of those assets from the broker-dealer’s own funds. This article will delve into the concept of 15C3-3 qualified custody of securities, discuss its importance, and explain the role of qualified custodians in secondary market trading of securities.
        </p>
      </article>

      <article tw="mt-10 space-y-6">
        <Subtitle>
          15c3-3 Qualified Custody of Securities
        </Subtitle>
        <p>
         The Securities and Exchange Commission (SEC) established Rule 15c3-3 in 1972 in response to a congressional directive to create rules concerning the acceptance, custody, and use of customer securities. Under this rule, broker-dealers are required to take steps to protect the securities that customers leave in their custody.
        </p>
        <p>
          Rule 15c3-3 ensures that brokerage firms maintain secure accounts so that clients can withdraw their assets at any time. These measures include the segregation of customer assets and proper record-keeping to help prevent the misuse of customer funds or securities.
        </p>
      </article>

      <article tw="mt-10 space-y-6">
        <Subtitle>
          Importance of Qualified Custody
        </Subtitle>
        <p>
          Qualified custody is important for several reasons:
        </p>
        <p>
          Customer protection: The primary goal of Rule 15c3-3 is to safeguard customer funds and securities. By ensuring proper segregation of customer assets and strict adherence to regulatory requirements, customers can have peace of mind that their investments are protected against potential broker-dealer insolvencies, fraud, or mismanagement.
        </p>
        <p>
          Ensuring financial stability: Qualified custody helps maintain the financial stability of the securities market by reducing the risk of broker-dealer failures that could have widespread consequences. By protecting customer assets, this rule promotes confidence in the financial system and encourages continued investment and participation in the securities market.
        </p>
        <p>
          Regulatory compliance: Broker-dealers that follow Rule 15c3-3 are better equipped to meet their regulatory obligations, which in turn helps to build trust among customers, regulators, and other stakeholders. Compliance with this rule demonstrates the broker-dealer’s commitment to protecting customer assets and maintaining high standards of conduct.
        </p>
      </article>

      <article tw="mt-10 space-y-6">
        <Subtitle>
          Role of Qualified Custodians in Secondary Market Trading of Securities
        </Subtitle>
        <p>
          Qualified custodians play a crucial role in the secondary market trading of securities by ensuring that customer assets are managed and held securely. A qualified custodian is a financial institution, such as a bank or trust company, that is authorized to hold and safeguard customer funds and securities according to the regulatory requirements outlined in Rule 15c3-3.
        </p>
        <p>
          In secondary market trading, investors buy and sell securities that have already been issued, such as stocks, bonds, and other financial instruments. Qualified custodians ensure that these transactions are conducted securely, transparently, and in accordance with applicable regulations. By doing so, they help to maintain investor confidence in the market and facilitate the smooth operation of secondary market trading.
        </p>
        <p>
          Additionally, qualified custodians play a vital role in the ongoing innovation and integration of new financial products into the securities market. For example, the SEC has issued a statement and request for comment regarding the custody of digital asset securities by broker-dealers, encouraging innovation around the application of Rule 15c3-3 to digital asset securities. 
        </p>
        <p>
          This highlights the importance of qualified custodians in adapting to new financial instruments ensuring that customer assets remain protected is upheld.
        </p>
      </article>

      <article tw="mt-10 space-y-6">
        <Subtitle>
          Conclusion
        </Subtitle>
        <p>
          Rule 15c3-3 qualified custody of securities is an essential component of the securities market, playing a crucial role in safeguarding customer assets and promoting investor confidence. The importance of qualified custody lies in the protection it offers to customers, the financial stability it helps maintain, and the regulatory compliance it enables for broker-dealers. 
        </p>
        <p>
          Qualified custodians are vital to the secondary market trading of securities, as they ensure that customer assets are managed securely and transparently, facilitating the smooth operation of the market and fostering innovation in the adoption of new financial products.
        </p>
        <br />
        <br />
        <p>
          Michael Hiles, Founder & CEO of 10XTS
        </p>
      </article>
      <br />
      <br />
      <br />
      <br />
      <hr />

      <cite tw="mt-10 space-y-6 text-sm block mt-5">
        <p>
          [1] “The Securities and Exchange Commission today issued a statement and request for comment regarding the custody of digital asset securities by broker-dealers in order to encourage innovation around the application of Securities Exchange Act Rule 15c3-3 to digital asset securities.”<br />
          URL: <Link to="https://www.sec.gov/news/press-release/2020-340" tw="hover:text-blue-800">https://www.sec.gov/news/press-release/2020-340</Link>
        </p>
      </cite>
      <cite tw="mt-10 space-y-6 text-sm block mt-5">
        <p>
          [2] “Rule 15c3-3 is an SEC rule that protects investors by requiring brokerage firms to maintain secure accounts so that clients can withdraw assets at any time.”<br />
          URL: <Link to="https://smartasset.com/investing/sec-rule-15c33" tw="hover:text-blue-800">https://smartasset.com/investing/sec-rule-15c33</Link>
        </p>
      </cite>
      <cite tw="mt-10 space-y-6 text-sm block mt-5">
        <p>
          [3] “The Commission adopted Rule 15c3-3 in 1972 in response to a congressional directive to create rules regarding, among other things, the acceptance, custody, and use of customer securities. The rule requires broker-dealers to take steps to protect the securities that customers leave in their custody.”<br />
          URL: <Link to="https://www.sec.gov/rules/final/34-47480.htm" tw="hover:text-blue-800">https://www.sec.gov/rules/final/34-47480.htm</Link>
        </p>
      </cite>
    </div>
  )
}
